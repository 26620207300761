.walletconnect-modal__base {
  .walletconnect-modal__mobile__toggle {
    color: #888;
    > a {
      cursor: pointer;
    }
  }
  .walletconnect-qrcode__image {
    cursor: auto;
  }
}

@for $i from 1 through 12 {
  .max-line-#{$i} {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: #{$i};
    -webkit-box-orient: vertical;
  }
}

.bg-gradient {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0), rgb(108, 93, 204) 50%, rgba(0, 0, 0, 0) 100%);
}

.go-jump {
  animation: jump ease-in-out 1.5s infinite alternate;
}

.go-scroll {
  animation: scroll 0.3s infinite;
}

.go-left {
  transform: translateX(0%);
  animation: left 30s linear infinite alternate;
}

.be-pulse {
  animation: pulse 0.5s linear infinite alternate;
}

.zoom-in-zoom-out {
  animation: zoom-in-zoom-out 0.75s linear infinite alternate;
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes scroll {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-5px);
  }
}

@keyframes left {
  to {
    transform: translateX(-50%);
  }
}

@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
    filter: brightness(1);
  }
  40% {
    transform: scale3d(1.05, 1.05, 1);
    filter: brightness(1.1);
  }
  100% {
    transform: scale3d(1.1, 1.1, 1);
    filter: brightness(1.2);
  }
}

.animateSpin {
  animation: animate-spin 30s linear infinite;
}
.animateCard1 {
  animation: animate-card-1 3s infinite alternate;
}
.animateCard2 {
  animation: animate-card-2 3s infinite alternate;
}
@keyframes animate-spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-card-1 {
  100% {
    transform: translateY(-50px) rotate(20deg);
  }
}
@keyframes animate-card-2 {
  100% {
    transform: translateY(-50px) rotate(-20deg);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
