@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~aos/dist/aos.css';
@import 'styles/components';
@import 'styles/mui';

body {
  font-size: 1rem;
  font-family: Nunito, sans-serif !important;
}

main {
  min-height: calc(100vh - 0px);
  background: url(assets/images/background.png) repeat center / contain;
}

* {
  outline: none !important;
}

a:hover {
  text-decoration: none !important;
}

body > iframe {
  display: none;
}

:root {
  --color-white: #eee;
  --color-primary: #ffaf36;
  --color-secondary: #228cee;
}

@font-face {
  font-family: 'Hornbill';
  src: url('/assets/fonts/Hornbill-Black.otf') format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: 'Hornbill';
  src: url('/assets/fonts/Hornbill-Bold.otf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Hornbill';
  src: url('/assets/fonts/Hornbill-Regular.otf') format('truetype');
  font-weight: 400;
}

.firefly {
  width: 4px;
  height: 4px;
  background: #c7ffff;
  border-radius: 50%;
  box-shadow: 0 0 0 5px #4a7bff;
  overflow: hidden;
}

.hamburger-icon {
  @media (min-width: 1280px) {
    display: none !important;
  }
}

.mobile-nav {
  display: flex;
  align-items: center;

  @media (max-width: 1279px) {
    align-items: stretch;
    position: fixed;
    inset: 100px 0 0 0;
    flex-direction: column;
    background-color: #1f0b57 !important;
    border-bottom: 1px solid silver;
    z-index: 999;
    transition: all 0.3s;

    > a {
      border-bottom: 1px solid #dedede;
    }
  }

  @media (min-width: 1280px) {
    display: flex !important;
    flex: 1;
    justify-content: center;
    gap: 20px;
  }
}
