.contained {
  border-radius: 8px !important;
  z-index: 1;
  @media (max-width: 800px) {
    min-height: 40px !important;
    min-width: 40px !important;
    font-size: 14px !important;
    + div {
      display: none !important;
    }
  }
  &:hover {
    filter: brightness(0.85);
  }
  + div {
    position: absolute;
    inset: 0 0 -4px;
    top: unset;
    height: 100%;
    border-radius: 8px;
  }
}

.containedPrimary {
  background: linear-gradient(180deg, #ffde48 0%, #ffbd0a 100%) !important;
  color: #722828 !important;
  box-shadow: inset 0px -2px 2px 1px #dba100 !important;
  + div {
    background-color: #c38f00;
  }
}

.containedSecondary {
  background: linear-gradient(180deg, #fc9669 0%, #f33935 100%) !important;
  color: #fff !important;
  box-shadow: inset 0px -2px 2px 1px #b8332f !important;
  + div {
    background-color: #9c0300;
  }
}

.containedInfo {
  background: linear-gradient(180deg, #3fb2ff 0%, #2380ff 100%) !important;
  color: #fff !important;
  box-shadow: inset 0px -2px 2px 1px #135fc2 !important;
  + div {
    background-color: #0c4fae;
  }
}

.containedInherit {
  background: linear-gradient(180deg, #cecece 0%, #9d9d9d 100%) !important;
  color: #fff !important;
  box-shadow: inset 0px -2px 2px 1px #7e7e7e !important;
  + div {
    background-color: #717171;
  }
}

.disabled {
  background: linear-gradient(180deg, #cecece 0%, #9d9d9d 100%) !important;
  color: #fff !important;
  box-shadow: inset 0px -2px 2px 1px #7e7e7e !important;
  + div {
    background-color: #717171;
  }
}
